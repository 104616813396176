<template>
  <div class="thanksPatronize">
    <img class="bg" src="@/assets/thanksPatronize/thanks.png" alt="" />
    <div class="thansWxOpenLaunchWeapp">
      <img src="../../assets/redEnvelopes/banner.gif" width="100%" alt="" />
      <wx-open-launch-weapp
        :username="journalParams.originalId"
        :path="journalParams.linkUrl"
        class="launch-btn"
        @click="sendMaidian"
      >
        <script type="text/wxtag-template">
          <style>.weapp-btn {width: 100vw; height: 100vw;}</style>
          <div class="weapp-btn">&nbsp;</div>
        </script>
      </wx-open-launch-weapp>
    </div>
    <div class="content">
      <div class="tip">
        <p>没中奖别灰心,老板今天</p>
        <p>不在,偷偷送你个红包,</p>
        <p>惊喜红包 <span>0.38</span> 元已到账</p>
      </div>
      <div class="bottom">
        <moreGift></moreGift>
      </div>
    </div>
  </div>
</template>

<script>
import { receiveRedPacket, journalApplet } from '@/api/index.js'
import moreGift from '@/components/moreGift/index.vue'
import setting from '@/setting.js'
export default {
  name: 'thanksPatronize',
  components: {
    moreGift
  },
  data() {
    return {
      journalParams: {
        location: 4, // 4：谢谢惠顾页顶部
        advertiseTitle: '今麦郎私域引流', // 广告标题
        advertiseType: 1, //广告类型 1：图片 2：视频
        pictureUrl: 'location', //图片地址
        videoUrl: 'location', //视频地址
        linkType: 2, // 1:h5; 2：小程序
        originalId: setting.originalId, //"小程序原始id"
        linkUrl: setting.linkUrl, //跳转链接
        operate: 1 // 操作：1：曝光 2：点击
      }
    }
  },
  methods: {
    // 点击小程序入口请求埋点
    sendMaidian() {
      journalApplet(Object.assign(this.journalParams, { operate: 2 })) // 专门针对小程序入口展示的埋点
    },
    returnBtn() {
      this.$router.go(-1)
    },
    getReceiveRedPacket() {
      receiveRedPacket({
        prizeId: this.$route.query.prizeId,
        drawLogId: this.$route.query.drawLogId
      })
    }
  },
  created() {
    this.getReceiveRedPacket()
  },
  mounted() {
    journalApplet(this.journalParams) // 专门针对小程序入口展示的埋点
  },
  // 只要不是从转盘页面来的说明是从电子奖品回来的，需要重定向
  beforeRouteEnter(to, from, next) {
    // 从转盘过来的
    if (sessionStorage.getItem('isFromTurntable')) {
      sessionStorage.removeItem('isFromTurntable')
      sessionStorage.setItem('showedThanksPatronize', true) //标记谢谢惠顾页面已经展示过了，用于返回转盘时，转盘页面获取，由于谢谢惠顾是不允许返回到转盘页面的，可以作为转盘页面重定向的依据

      next()
    } else {
      // 从电子奖品返回的
      sessionStorage.setItem('thanks', true)
      sessionStorage.removeItem('showedThanksPatronize') //防止携带back 11值
      next({
        path: '/codeUsed',
        query: { isNoBack: true } //标记重定向进去后如果再次抽奖不用传递back值
      })
    }
  }
}
</script>

<style scoped lang="less">
.thanksPatronize {
  width: 100%;
  height: 100%;
  background: #fff;
  position: relative;
  .bg {
    width: 100%;
  }
  .content {
    position: absolute;
    top: 680px;
    width: 100%;
    .tip {
      margin-top: -20px;
      width: 100%;
      p {
        width: 100%;
        text-align: center;
        font-size: 30px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #fcee98;
        line-height: 51px;
        span {
          font-size: 44px;
          color: #d13e21;
          text-shadow: #fff 2px 0 0, #fff 0 2px 0, #fff -2px 0 0, #fff 0 -2px 0;
        }
      }
    }
    .bottom {
      margin-top: 120px;
    }
  }
}

.thansWxOpenLaunchWeapp {
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  width: 668px;
  height: 186px;
  border: 4px solid #fff;
  border-radius: 20px;
  overflow: hidden;

  img {
    display: block;
    width: 668px;
    border-radius: 20px;
  }

  .launch-btn {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
  }
}
</style>
